@charset "UTF-8";

@import "variable";
@import "reset";
@import "component";


/* ==========================================================================
  Base
  ========================================================================== */

* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  color: colors(default);
  font-size: 1.6em;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: .02em;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  @include media(pc) {
    min-width: 1280px;
  }
  @include media(sp) {
    font-size: 1.4em;
  }
}
img {
  width: 100%;
  height: auto;
}
::selection {
  background: colors(orange);
  color: #fff;
}
/* ---------------
	Font
------------------ */
.en {
  @include ft;
}

/* ---------------
	Link
------------------ */
a {
  text-decoration: none;
  &:link { color: #000; }
  &:visited { color: #000; }
  &:hover { color: colors(orange); }
  &:active { color: colors(orange); }
  transition: all .25s ease-out;
}
@include media(pc) {
a img {
  transition: all .25s ease-out;
}
a:hover img {
  opacity: .5;
  filter: grayscale(100%);
}
}

/* ---------------
	Button
------------------ */
.btn-wrap {
  display: flex;
  justify-content: center;
  a.btn {
    display: block;
    min-width: 85px;
    margin: 0 2.5%;
    padding: 6px 8px;
    border-radius: 2px;
    border: 1px solid #000;
    @include ft;
    text-align: center;
    &:hover {
      background: #000;
      color: #fff;
    }
    &-blk {
      background: #000;
      color: #fff;
      &:hover {
        background: #fff;
        color: #000;
        border: 1px solid #fff;
      }
    }
    &-wht {
      background: #fff;
      color: #000;
      &:hover {
        background: colors(orange);
        color: #fff;
      }
    }
  }
}

/* ---------------
	Animation
------------------ */
.animation {
  opacity: 0;
  transition: opacity .5s ease-out;
  &.is-show {
    opacity: 1;
  }
}

.dropin {
  opacity: 0;
  transition: opacity .5s ease-out;
  &.is-show {
    opacity: 1;
  }
}

/* ==========================================================================
  Responsive
  ========================================================================== */
.pc {
  display: block;
  @include media(sp) {
    display: none;
  }
}
.sp {
  display: none;
  @include media(sp) {
    display: block;
  }
}


/* ==========================================================================
  Layout
  ========================================================================== */
.l-main, .l-aside, .l-footer, .l-inner {
  position: relative;
}
.l-wrapper {
	padding-top: 58px;
}


/* ==========================================================================
  Header
  ========================================================================== */
.l-header {
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100%;
	height: 58px;
	background: rgba(255,255,255,.9);
// 	mix-blend-mode: difference;
}
@include media(pc) {
  .l-header {
    background: rgba(255,255,255,.9);
  }
}
.header-inner {
	padding: 8px 8px 8px 58px;
}
.header-logo {
  position: absolute;
	width: 42px;
	height: auto;
	left: 58px;
	top: 8px;
}

.cls-1{
	fill: #f19100;
}
.cls-2{
	fill: #fff;
}
// .back-cnts {
// 	fill: none;
// 	stroke: #f19100;
// 	stroke-width: 0.1rem;
// }

/* ---------------
	SNS
------------------ */
/* SNS(small) */
.l-header {
  .sns {
    position: absolute;
    right: 8px;
    top: 14px;
  }
}


.sns {
  overflow: hidden;
}
.sns ul {
  display: table;
  margin: 0 auto 0;
  text-align: center;
}
.sns ul li {
  display: table-cell;
  padding: 0 4px;
}
.sns ul li a {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
.sns ul li a .icon-fill {
  transition: all 0.25s ease-out;
  fill:#000;
}
.sns ul li a .icon-frame {
  transition: all 0.5s ease-out;
  fill:#fff;
  transform: scale(0.0);
  transform-origin: 50% 50% 0;
}
@include media(pc) {
  .sns ul li a:hover .icon-fill {
    fill:#fff;
  }
  .sns ul li a:hover .icon-frame {
    fill:#000;
    transform: scale(1.0);
  }
}


/* ==========================================================================
  Navigation
  ========================================================================== */
/*開閉ボタン*/
@include media(sp) {
  .nav-trigger {
  	position: fixed;
  	z-index: 101;
  	width: 40px;
  	height: 40px;
  	top: 8px;
  	left: 8px;
  	z-index: 100;
  	background: #000;
  	border-radius: 4px;
  	padding: 8px;
  	transition: 0.5s ease-out;	
  	div {
  		position: relative;
  		span {
  			display: block;
  			width: 100%;
  			height: 1px;
  			background: #fff;
  			position:absolute;
  			left: 0;
  			transition: 0.5s ease-out;	
  		}
  	}
  }
  .nav-trigger.active {
    background: #fff;
  }
  .nav-trigger span {
    &:nth-of-type(1) {
  	  top: 3px;
    }
    &:nth-of-type(2) {
      top: 11px;
      width: 75%;
    }
    &:nth-of-type(3) {
      top: 19px;
      width: 55%;
    }
  }
  
  .nav-trigger.active span {
    background: #000;
    &:nth-of-type(1),&:nth-of-type(3) {
      width: 10px;
    }
    &:nth-of-type(2) {
      width: 100%;
    }
    &:nth-of-type(1) {
      transform: translate(-1px,4px) rotate(-45deg);
    }
    &:nth-of-type(3) {
      transform: translate(-1px,-4px) rotate(45deg);
    }
  }
}



.nav-wrap {
  h3 {
    color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 15px;
    font-size: 2.4rem;
  }
  .cat-list {
    position: relative;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        margin-bottom: 15px;
        a {
          display: block;
          position: relative;
          font-size: 1.2rem;
          color: #fff;
          padding: 0 10px 0 20px;
          &::after {
            content: "";
            position: absolute;
            display: block;
            width: 6px;
            height: 1px;
            top: auto;
            bottom: 2px;
            right: auto;
            left: 10px;
            margin: auto auto 0 0;
            background: #fff;
          }
        }
      }
    }
  }
  .tag-list {
    position: relative;
    padding-bottom: 26px;
    margin-bottom: 15px;
    @include media(pc) {
      padding-bottom: 37px;
      margin-bottom: 30px;
    }
    .btn-more {
      position: absolute;
      margin: 0;
      left: 10px;
      bottom: 0;
      a {
        display: block;
        position: relative;
        background: transparent;
        padding: 2px 6px 6px;
        border-radius: 3px;
        border: 1px solid #fff;
        line-height: 1;
        &::before {
          content: "MORE TAGS";
          font-size: 1.0rem;
          line-height: 1;
          color: #fff;
        }
      }
    }
    ul {
      position: relative;
      padding: 0 10px;
      max-height: 96px;
      overflow: hidden;
      transition: max-height 0.25s;
      li {
        display: inline-block;
        margin-bottom: 10px;
        a {
          display: block;
          position: relative;
          height: 22px;
          font-size: 1.0rem;
          line-height: 1;
          color: #000;
          padding: 6px 9px 5px;
          border-radius: 11px;
          background: #eee;
          &:hover {
            background: #bbb;
          }
        }
      }
      
    }
  }
  .tag-list.is-active {
    .btn-more {
      a {
        &::before {
          content: "CLOSE";
        }
      }
    }
    ul {
      max-height: 100vh;
    }   
  }
}


// ナビゲーション本体
@include media(sp) {
  .nav-wrap {
    position: fixed;
    overflow: hidden;
    left: -100%;
  	top: 0;
  	z-index: 99;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: 0.25s ease-out;
    padding: 66px 0 8px;
    overflow-y: auto;
  }
  .nav-wrap.active {
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
  }
  // ナビゲーション
  .nav-list ul {
    li {
      font-size: 2.4rem;
      color: #fff;
      &.design {
        a {
          cursor: default;
        }
      }
    }
    a {
  		overflow: hidden;
  		position: relative;
      display: block;
  		padding: 10px 10px 0;
  		margin-bottom: 20px;
      opacity: 0;
  		color: #fff;
  		line-height: 0.9;
      transition: 0.5s ease-out;
      span.nav-text {
  			position: relative;
  			z-index: 1;
  			display: inline-block;
        @include ft;
        font-size: 2.4rem;
  		}
  		span.nav-line {
  			position: absolute;
  			// top: 0;
  			bottom: 0;
  			left: 0;
  			width: 100%;
  			height: 1px;
  			margin: 0 auto;
  			background: #333;
  			transform: scaleX(0);
  			transform-origin: top left;
  		}
  		span.nav-line2 {
  			position: absolute;
  			// top: 0;
  			bottom: 0;
  			right: 5%;
  			width: 22px;
  			height: 1px;
  			margin: 0 auto;
  			background: #fff;
  			&::after {
  			  content: "";
  			  position: absolute;
  			  display: block;
  			  width: 7px;
  			  height: 1px;
  			  top: auto;
  			  bottom: 3px;
  			  right: -1px;
  			  left: auto;
  			  margin: auto 0 0 auto;
  			  background: #fff;
  			  transform: rotate(45deg);
  			}
  		}
    }
  }
  .nav-wrap.active .nav-list ul {
    a {
      opacity: 1;
    }
  }
}

//PCの場合は常に表示
@include media(pc) {
  .nav-wrap {
    position: fixed;
    overflow-y: auto;
    left: 0;
  	top: 0;
  	z-index: 999;
    width: 300px;
    height: 100%;
    background: #000;
    transition: 0.25s ease-out;
    .logo {
      width: 96px;
      margin: 20px;
    }
  }
  // ナビゲーション
  .nav-list ul {
    padding: 46px 0 8px;
    li.design {
/*
      padding: 10px 10px 0;
  		margin-bottom: 20px;
      line-height: 0.9;
      @include ft;
      font-size: 2.4rem;
      color: #fff;
*/
      a {
        span.nav-line {
          display: none;
        }
      }
    }
    a {
  		overflow: hidden;
  		position: relative;
      display: block;
  		padding: 10px 10px 0;
  		margin-bottom: 20px;
      opacity: 0;
  		color: #fff;
  		line-height: 0.9;
      transition: 0.25s ease-out;
      &:hover {
        color: colors(orange);
      }
      span.nav-text {
  			position: relative;
  			z-index: 1;
  			display: inline-block;
        @include ft;
        font-size: 2.4rem;
  		}
  		span.nav-line {
    		display: none;
  			position: absolute;
  			// top: 0;
  			bottom: 0;
  			left: 0;
  			width: 100%;
  			height: 1px;
  			margin: 0 auto;
  			background: #333;
  			transform: scaleX(1);
  			transform-origin: top left;
  		}
  		span.nav-line2 {
    		display: block;
  			position: absolute;
  			// top: 0;
  			bottom: 0;
  			right: 110%;
  			width: 100%;
  			height: 100%;
  			margin: 0 auto;
  			background: #fff;
  			transition: all .25s ease-out;
  			&::after {
  			  content: "";
  			  position: absolute;
  			  display: block;
  			  width: 7px;
  			  height: 1px;
  			  top: auto;
  			  bottom: 3px;
  			  right: -1px;
  			  left: auto;
  			  margin: auto 0 0 auto;
  			  background: #fff;
  			  transform: rotate(45deg);
  			}
  		}
    }
  }
  .nav-wrap .nav-list ul {
    a {
      opacity: 1;
      position: relative;
      &:hover {
  			span.nav-line2 {
    			right: 0;
    		}
      }
    }
  }
  .nav-wrap .cat-list ul {
    li {
      margin-bottom: 15px;
      a {
        display: block;
        position: relative;
        font-size: 1.2rem;
        color: #fff;
        padding: 0 10px 0 20px;
        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 6px;
          height: 1px;
          top: auto;
          bottom: 2px;
          right: auto;
          left: 10px;
          margin: auto auto 0 0;
          background: #fff;
        }
      }
    }
  }
  .nav-wrap .tag-list ul {
    padding: 0 10px;
    li {
      display: inline-block;
      margin-bottom: 15px;
      a {
        display: block;
        position: relative;
        height: 22px;
        font-size: 1.1rem;
        line-height: 1;
        color: #000;
        padding: 5px 10px;
        border-radius: 11px;
        background: #eee;
        &:hover {
          background: #bbb;
        }
      }
    }
  }
}



/* ----------------
----------------
Pagenation
----------------
---------------- */
.pagenation {
  margin: 5% auto 0;
  width: 100%;
  text-align: center;
}
.pagenation ul {
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
}
.pagenation ul li {
  display: block;
  overflow: hidden;
  border-radius: 3px;
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  border: 1px solid #1a1a1a;
  @include ft;
  margin: 0 10px;
}
.pagenation ul li.next,
.pagenation ul li.prev {
  width: 50px !important;
}
.pagenation ul li a {
  color: #1a1a1a;
  display: block;
}
.pagenation ul li a:hover {
  background: #1a1a1a;
  color: #fff;  
}
.pagenation ul li.active {
  color: #fff;
  background: #1a1a1a;
}


@include media(pc) {
.pagenation {
  margin: 30px auto 60px;
  width: 100%;
  max-width: 720px;
}
.pagenation ul li {
  display: block;
  border-radius: 3px;
  width: 36px !important;
  height: 36px !important;
  font-size: 18px;
  line-height: 34px;
  margin: 0 10px;
}
.pagenation ul li.next,
.pagenation ul li.prev {
  width: 60px !important;
}
}


/* ==========================================================================
  Footer
  ========================================================================== */
.l-footer {
  position: relative;
  text-align: center;
  padding: 8px;
  font-size: 1.2rem;
  @include ft-h;
  background: colors(orange);
  color: #fff; 
}
@include media(pc) {
  .l-footer {
    position: fixed;
    left: -290px;
    bottom: 0;
    z-index: 10000;
    background: rgba(0,0,0,.9);
  }
}


// ページトップボタン
.btn-gotop {  
//display: block;
  display: none;
  position: fixed;
  left: -15px;
  bottom: 30px;
  margin: 0;
  padding: 4px;
  transform: rotate(-90deg);
  z-index: 100;
  a {
    position: relative;
    display: block;
    width: 68px;
    height: 20px;
    span {
      color: colors(orange);
      font-size: .9rem;
    }
    &::before,&::after {
      @include pseudo;
      height: 1px;
      background: colors(orange);
      z-index: 2;
    }
    &::before {
      width: 10px;
      right: -1px;
      bottom: 12px;
      transform: rotate(45deg)
    }
    &::after {
      width: 70px;
      right: 0;
      bottom: 8px;
    }
  }
}

/* ==========================================================================
  Main
  ========================================================================== */
@include media(pc) {
  .l-main,
  .l-footer {
    padding-left: 300px;
  }
}

/* ==================
  common
  ================== */
.none-txt {
  @include ft;
  font-size: 2.2rem;
  text-align: center;
  padding: 4.0rem 0;
}



/* ==========================================================================
  TOP PAGE
  ========================================================================== */

/* ==================
  common
  ================== */
.cnts h2 {
  @include ft;
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}
@include media(pc) {
  .cnts h2 {
    font-size: 3.8rem;
    margin-bottom: 30px;
  }
}

.cnts .swiper-slide {
  a {
    display: block;
    width: 100%;
    height: 100%;
    article {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .txt {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: transparent;
        text-align: center;
        display: none;
        border: 0px solid #fff;
        transition: border .25s ease-out;
        span {
          display: block;
          color: #fff;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1.8;
        }
      }
    }
  }
}
@include media(pc) {
.cnts .swiper-slide {
  a:hover {
    article {
      background: rgba(0,0,0,1);
      .thumb {
        img {
          transform: scale(1.1);
        }
      }
      .txt {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: block;
          
        }
      }
    }
  }
}
}

/* ==================
  Logo
  ================== */
.cnts-logo {
  margin-top: 15%;
  margin-bottom: 15%;
  text-align: center;
  h1 {
    width: 50%;
    max-width: 320px;
    margin: 0 auto 15px;
  }
  .catch {
    position: relative;
    overflow: hidden;
    margin-bottom: 15%;
    height: 18px;
    span {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      margin: auto auto;
      @include ft-h;
      font-size: 1.8rem;
    }
  }
  .anc {
    ul {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      overflow: hidden;
        height: 18px;
      li {
        width: 25%;
        position: relative;
        height: 18px;
        border-right: 1px solid #000;
        
        &:last-child {
          border: none;
        }
/*
        &:nth-child(1) {
          width: 60px;
        }
        &:nth-child(2) {
          width: 80px;
        }
        &:nth-child(3) {
          width: 50px;
        }
        &:nth-child(4) {
          width: 60px;
        }
*/
        a {
          position: absolute;
          display: block;
          width: 100%;
          text-align: center;
          text-decoration: none;
          @include ft;
          font-size: 1.8rem;
        }
      }
    }
  }
}
@include media(pc) {
.cnts-logo {
  position: relative;
  margin-top: 60px;
  margin-bottom: 30px;
  h1 {
    width: 256px;
    height: 248px;
    margin: 0 auto 30px;
  }
  .catch {
    height: auto;
    margin-bottom: 90px;
    height: 32px;
    span {
      font-size: 2.4rem;
    }
  }
  .anc {
    position: relative;
    width: 60%;
    margin: 0 auto;
    ul {
      position: relative;
      width: 100%;
      margin-bottom: 90px;
      li {
        width: 25%;
        a {
          text-decoration: none;
          font-size: 2.2rem;
        }
      }
    }
  }
}
}

/* ==================
  Recent Works
  ================== */

@include media(pc) {
/*
.cnts-recent {
  min-height: 270px;
}
*/
}



.ttl-insta {
  position: relative;
  width: 34%;
  text-align: center;
  font-size: 1.3rem;
  background: #1a1a1a;
  color: #fff;
  border-radius: 4px 4px 0 0;
  margin: 0 auto;
  padding: 7px 0 0;
  a {
    display: block;
    position: relative;
    color: #fff !important;
    &::before {
      content: "";
      position: relative;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url(../images/common/icon_insta_s_wht.svg) no-repeat;
      background-size: 100% 100%;
      margin-right: 5px;
      vertical-align: bottom;
    }
  }
}
@include media(pc) {
.ttl-insta {
  width:20%;
  font-size: 1.8rem;
  color: #fff;
  border-radius: 4px 4px 0 0;
  margin: 0 auto;
  padding: 15px 0 7px;
  a {
    &::before {
      width: 18px;
      height: 18px;
      margin-right: 7px;
      vertical-align: top;
    }
  }
}
}



/* ==================
  News-list
  ================== */
.cnts-news {
  background: #fff;
  padding: 15% 0 15%;
  h2 {
    color: #000;
  }
  .news-list ul	 {
    margin-bottom: 30px;
    &::after {
      content: none !important;
    }
  }
  article {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
    line-height: 1.4;
    padding: 0 2.5% 10px;
    border-bottom: 1px solid #f0f0f0;
    .date {
      width: 20%;
      @include ft;
      text-align: left;
      font-size: 1.3rem;
    }
    .txt {
      font-size: 1.2rem;
      width: 75%;
//       padding-right: 2.5%;
      text-align: left;
    }
  }
  .btn {
    background: #fff;
  }
}
@include media(pc) {
.cnts-news {
  padding: 90px 0;
  .news-list ul	 {
    width: 60%;
    margin: 0 auto 30px;
  }
  article {
    margin-bottom: 15px;
    padding: 0 0 15px;
    .date {
      width: 12%;
      @include ft;
      font-weight: 700;
      text-align: left;
      font-size: 1.6rem;
    }
    .txt {
      font-size: 1.6rem;
      width: 85%;
    }
  }
}
}


/* ==================
  Music Works / Media Works /MD
  ================== */
.cnts-works {
  background: #1a1a1a;
  .l-inner {
    position: relative;
    padding: 0;
  }
  .sec-wrap {
    position: relative;
  }
  header {
    position: absolute;
    display: inline-block;
    height: 50px;
    width: 40%;
    left: 0;
    right: 0;
    top: 230px;
    padding: 0;
    margin: 0 auto auto;
    background: #1a1a1a;
    z-index: 3;
    border-radius: 4px 4px 0 0;
    h2 {
      position: relative;
      line-height: 50px;
    }
  }
  .cnts-txt {
    padding: 15% 0 15%;
    background: #1a1a1a;
    color: #fff;
  }
  h3 {
    text-align: center;
    margin: 0 auto 10px;
    img {
      display: block;
      width: 96px;
      margin: 0 auto 10px;
    }
    span {
      font-size: 1.6rem;
      @include ft-cnd-b;
    }
  }
  p.lead {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.8rem;
    @include ft-cnd-b;
  }
  p {
    text-align: center;
    line-height: 1.4;
    &.jp {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
    &.en {
      @include ft;
      font-size: 1.1rem;
    }
  }
  .btn-wrap {
    padding-top: 30px;
  }
}
.carousel {
  margin-bottom: 0;
  width: 100%;
  background: #1a1a1a;
  padding: 1px 0;
  .thumb {
    position: relative;
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
  }
}

@include media(pc) {
.cnts-works {
  .l-inner {
    padding: 0;
  }
  .sec-wrap {
    position: relative;
  }
  header {
    position: absolute;
    display: inline-block;
    height: 80px;
    width: 20%;
    left: 0;
    right: 0;
    top: 360px;
    padding: 0;
    margin: 0 auto auto;
    background: #1a1a1a;
    z-index: 3;
    border-radius: 4px 4px 0 0;
    h2 {
      position: relative;
      line-height: 80px;
    }
  }
  .cnts-txt {
    padding: 90px 0;
    background: #1a1a1a;
    color: #fff;
  }
  h3 {
    margin: 0 auto 25px;
    img {
      width: 150px;
      margin: 0 auto 15px;
    }
    span {
      font-size: 1.8rem;
    }
  }
  p.lead {
    margin-bottom: 30px;
    font-size: 2.4rem;
  }
  p {
    line-height: 1.6;
    &.jp {
      font-size: 1.6rem;
      margin-bottom: 15px;
    }
    &.en {
      @include ft;
      font-size: 1.3rem;
    }
  }
  .btn-wrap {
    padding-top: 30px;
  }
}
.carousel {
  margin-bottom: 0;
  width: 100%;
  .thumb {
    position: relative;
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
}
}

/* ==================
  Music Works / Media Works
  ================== */
.cnts-design {
  header {
    background: #1a1a1a;
    h2 {
/*
      span.blk {
        color: #fff;
      }
      span.wht {
        color: #fff;
      }  
*/    
    }
  }
  .design-sec-wrap {
    position: relative;
    display: flex;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 1px;
      height: 72%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: #333;
      z-index: 1;
    }
    .cnts-txt {
      padding: 30% 0 30%;
    }
    .design-sec-music,
    .design-sec-media {
      width: 50%;
    }
/*
    .design-sec-music {
      .btn-wrap {
        a {
          background: #fff;
          color: #000;
        }
      }
    }
    .design-sec-media {
      background: #000;
      background: #fff;
      color: #000;
      .btn-wrap {
        a {
          background: #fff;
          color: #000;
        }
      }
    }
*/
  }
}

@include media(pc) {
.cnts-design {
  .design-sec-wrap {
    &::after {
      height: 72%;
    }
    .cnts-txt {
      padding: 90px 0;
    }
  }
}
}

/* ==================
  MD
  ================== */
.cnts-merchandise {
  position: relative;
  header {
    width: 30%;
  }
  .sec-wrap {
    .cnt-txt {
      padding: 15% 0 15%;
    }
  }
}
@include media(pc) {
.cnts-merchandise {
  header {
    width: 20%;
  }
  .sec-wrap {
    .cnt-txt {
      padding: 90px 0;
    }
  }
}
}


/* ==================
  Others
  ================== */
.cnts-others {
  background: #f19100;
  color: #fff;
  overflow: hidden;
  .l-inner {
    padding: 15% 0;
  }
  h2 {
    text-align: center;
    margin: 0 auto 10px;
    img {
      display: block;
      width: 96px;
      margin: 0 auto 10px;
    }
    span {
      font-size: 1.6rem;
      @include ft-cnd-b;
    }
  }
  h2 {
    @include ft;
    font-size: 2.2rem;
    margin-bottom: 30px;
  }
}



@include media(pc) {
.cnts-others {
  .l-inner {
    padding: 60px 0 90px;
  }
  h2 {
    text-align: center;
    margin: 0 auto 30px;
    img {
      display: block;
      width: 128px;
      margin: 0 auto 15px;
    }
    span {
      font-size: 1.6rem;
    }
  }
  h2 {
    font-size: 3.8rem;
    margin-bottom: 30px;
  }
}
}


// サービス一覧
.services-container {
  .swiper-wrapper {

  }
  .swiper-slide {
    position: relative;
    background: #fff;
    color: #000;
    padding: 30px 10px;
    border-radius: 4px;
    min-height: 270px;
  }
  h3 {
    text-align: center;
    margin-bottom: 15px;
    font-size: 2.8rem;
    @include ft-cnd-b;
    small {
      display: block;
      font-size: 50%;
      font-weight: bold;
    }
    img {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
  }
  p {
    text-align: center;
    line-height: 1.4;
    &.jp {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
    &.en {
      @include ft;
      font-size: 1.1rem;
    }
  }
  .btn-wrap {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    top: auto;
    margin: auto auto 0;
    a {
      width: auto;
      display: inline-block;
      margin: 0 auto;
    }
  }
}
@include media(pc) {
.services-container {
  .swiper-slide {
    min-height: 330px;
  }
  h3 {
    margin-bottom: 15px;
    font-size: 2.8rem;
    img {
      width: 60%;
    }
  }
  p {
    text-align: center;
    line-height: 1.4;
    &.jp {
      font-size: 1.6rem;
      margin-bottom: 5px;
    }
    &.en {
      font-size: 1.3rem;
    }
  }
  .btn-wrap {
    padding-top: 30px;
    a {
      &:hover {
        color: #fff;
        background: colors(orange);
        border: 1px solid colors(orange);
      }
    }
  }
}
}


/* ==========================================================================
  ARCHIVE PAGE 
  ========================================================================== */
.cnts-explain {
  background: #1a1a1a;
  .l-inner {
    padding: 15% 2.5%;
  }
  .cnt-txt {
    background: #1a1a1a;
    color: #fff;
    text-align: center;
    h2 {
      width: 50%;
      max-width: 320px;
      margin: 0 auto 30px;
    }
    .lead {
      @include ft-cnd-b;
      font-size: 2.0rem;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.4;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@include media(pc) {
.cnts-explain {
  .l-inner {
    padding: 90px 2.5%;
  }
  .cnt-txt {
    h2 {
      width: 50%;
      max-width: 220px;
      margin: 0 auto 30px;
    }
    .lead {
      @include ft-cnd-b;
      font-size: 2.4rem;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.6rem;
      line-height: 1.8;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
}


.cnts-archive {
  position: relative;
  padding: 15% 0;
  h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.2rem;
  }
}
@include media(pc) {
.cnts-archive {
  padding: 60px 0;
  h3 {
    margin-bottom: 30px;
    font-size: 3.2rem;
  }
}
}

.tax-ttl {
  background: #1a1a1a;
  color: #fff;
  padding: 15px 0 5px !important;
  .type {
    text-align: center;
    font-size: 1.5rem;
  }
  h1 {
    font-size: 2.2rem  !important;
  }
}
@include media(pc) {
.tax-ttl {
  padding: 30px 0 10px !important;
  .type {
    text-align: center;
    font-size: 1.8rem;
  }
  h1 {
    font-size: 2.8rem  !important;
  }
}
}

/* ==========================================================================
  
  NEWS (category-news.php / content.php)
  
  ========================================================================== */
/* ==================
  News List
  ================== */
.news-wrap {
  .news-cat {
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    li {
      margin: 0;
      display: block;
      width: 25%;
      text-align: center;
      @include ft-h;
      border-bottom: 2px solid #ccc;
      &.is-active {
        border-bottom: 2px solid #000;
      }
      span {
        display: block;
        padding: 5px 0 15px;
        font-family: futura-pt, sans-serif;
        font-size: 1.4rem;
        font-weight: 700;
        font-style: normal;
      }
    }
  }
  .news-panel {
    display: block;
    margin-bottom: 30px;
    background: #fff;
    padding: 0;
    li {
      width: 100%;
      margin: 0;
      padding: 0;
      .news-list {
        width: 100%;
        margin: 0;
        padding: 15px 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: transparent;
        &:before{
          content:"";
          display:block;
          width: 22.5%;
          height:0;
          order:1;
        }
        &:after{
          content:"";
          display:block;
          width: 22.5%;
          height:0;
        }
        li {
          width: 47%;
    			margin-bottom: 30px;
          padding: 0 0 15px;
          border-bottom: 1px solid #000;
    /*
          &:nth-child(odd) {
            background: #efefef;
          }
    */
          a {
            @include arrow;
    				@include arrow-right;
    				height: 100%;
    				padding: 0 0 10px;
    				margin-top: 0;
          }
          article {
    				.thumb {
    					margin-bottom: 10px;
    				}
            .date {
              margin-bottom: 5px;
              @include ft-h;
              font-size: 1.4rem;
            }
            h2 {
    					margin-bottom: 10px;
              line-height: 1.4;
    					font-size: 1.4rem;
    					text-align: left;
    				}
    				.cat {
              @include ft-h;
              font-size: 1rem;
            }
          }

        }
      }
      .notfound {
        padding: 15% 0;
        text-align: center;
        span {
          display: block;
          margin-bottom: 1em;
          .jp {
            
          }
          .en {
            @include ft-h;
          }
        }
      }
    }
  }
}

.news-detail {
	margin-bottom: 30px;
	.img-main {
		overflow: hidden;
		position: relative;
		min-height: 200px;
		margin-bottom: 20px;
		img {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
		}
	}
	.news-ttl {
		margin-bottom: 30px;
		padding-bottom: 5px;
		border-bottom: 1px solid #000;
		.date {
			@include ft-h;
			margin-bottom: 10px;
			font-size: 1.4rem;
		}
		h2 {
			margin-bottom: 15px;
			text-align: left;
			line-height: 1.4;
		}
	}
	.news-cnt {
		margin-bottom: 20px;
		padding-bottom: 15px;
		border-bottom: 1px solid #000;
		p {
			margin-bottom: 15px;
			line-height: 1.75;
		}
	}
}


@include media(pc) {
.news-wrap {
  .news-cat li span {
    font-size: 1.6rem;
  }
  .news-panel {
    li {
      .news-list {
        padding: 30px 0 0;
        li {
          width: 22.5%;
        }
      }
      .notfound {
        font-size: 1.6rem;
      }
    }
  }
}

.news-detail {
	margin-bottom: 30px;
	.img-main {
		overflow: hidden;
		position: relative;
		min-height: 200px;
		margin-bottom: 20px;
		img {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
		}
	}
	.news-ttl {
		.date {
			font-size: 1.6rem;
		}
		h2 {
			font-size: 2.6rem;
		}
	}
	.news-cnt {
		p {
  		font-size: 1.4rem;
		}
	}
}
}



/* ==========================================================================
  WORKS PAGE (MUSIC / MEDIA / MERCHANDISE )
  ========================================================================== */



/* ==================
  Recent List
  ================== */
.recent-list {
  ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2.5% 2.5% 0;
    background: #1a1a1a;
    &::after {
      content: '';
      display: block;
      width: 31.5%;
      height: 0;
    }
    
    li {
      width: 31.5%;
      margin-bottom: 2.5%;
      a, img {
        display: block;
      }
      a {
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}
@include media(pc) {
.recent-list {
  ul {
    padding: 15px 15px 0;
    &::after{
      width: 15.5%;
    }
    li {
      width: 15.5%;
      margin-bottom: 15px;
      a, img {
        display: block;
      }
      a {
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}
}


/* ==================
  Archive List
  ================== */
.archive-list,
.thumbs-list {
  ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 2.5% 0;
    padding-top: 2.5%;
    background: #fff;
    &::after {
      content: '';
      display: block;
      width: 31.5%; 
    }
    li {
      width: 31.5%;
      margin-bottom: 2.5%;
      a, img {
        display: block;
      }
      a {
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}


@include media(pc) {
.archive-list,
.thumbs-list {
  ul {
    padding: 0 5% 0;
    background: #fff;
    &::before,
    &::after {
      content: "";
      display: block;
      width: 23%;
      height: 0;
    }
    &::before {
      order: 1;
    }
    li {
      width: 23%;
      margin-bottom: 30px;
      a, img {
        display: block;
      }
      a {
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}
}


/* ==========================================================================
  WORKS PAGE (DETAIL)
  ========================================================================== */
.cnts-detail {
  .btn-wrap {
    padding: 30px 0;
  }
}
.detail-img {
  .thumb {
    max-width: 640px;
    margin: 0 auto 15px;
  }
}
.detail-txt {
  margin: 0 auto 30px;
  padding: 0 5%;
  letter-spacing: .05em;
  h1 {
    font-size: 2.0rem;
    margin-bottom: 15px;
    line-height: 1.4;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@include media(pc) {
.cnts-detail {
  padding-top: 30px;
  .btn-wrap {
    padding: 30px 0;
  }
}
.detail-img {
  .thumb {
    max-width: 640px;
    margin: 0 auto 15px;
  }
}
.detail-txt {
  max-width: 640px;
  margin: 0 auto 30px;
  padding: 30px 0;
  h1 {
    font-size: 2.4rem;
    line-height: 1.4;
  }
  p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
}

.cnts-detail {
  .cats, .tags {
    padding-top: 15px;
    h6 {
      margin-bottom: 10px;
    }
    ul {
      font-size: 0;
      li {
        display: inline-block;
        letter-spacing: -.04em;
        margin-bottom: 10px;
        margin-right: 10px;
        a {
          display: block;
          padding: 4px 6px;
          border-radius: 4px;
          background: #f5f5f5;
          font-size: 1.0rem;
        }
      }
    }
  }
  .date {
    padding-top: 15px;
    margin-bottom: 30px;
  }
  .share {
    text-align: center;
    h6 {
      font-weight: bold;
      font-size: 1.4rem;
      padding-right: 10px;
    }
  }
  .sns {
    text-align: center;
    padding: 15px 0 0;
    li {
      vertical-align: middle;
    }
  }
}
@include media(pc) {
.cnts-detail {
  .cats, .tags {
    padding-top: 30px;
    h6 {
      margin-bottom: 10px;
    }
    ul {
      font-size: 0;
      li {
        display: inline-block;
        letter-spacing: -.04em;
        margin-bottom: 10px;
        margin-right: 10px;
        a {
          font-size: 1.2rem;
          background: #f5f5f5;
          &:hover {
            color: #fff;
            background: colors(orange);
          }
        }
      }
    }
  }
  .date {
    padding-top: 30px;
  }
  .share {
    text-align: center;
    h6 {
      font-weight: bold;
      font-size: 1.4rem;
      padding-right: 10px;
    }
  }
  .sns {
    text-align: center;
    padding: 15px 0 0;
    li {
      vertical-align: middle;
    }
  }
}
}

/* ==========================================================================
  Not Found
  ========================================================================== */
/* ==================
  Not Found
  ================== */
.cnts-none {
  .l-inner {
    padding: 0 5% 60px;
    p {
      text-align: center;
      margin-bottom: 1em;
    }
  }
}



/* ==========================================================================
  PAGE
  ========================================================================== */
.ttl {
  padding: 2.5% 0;
  h1 {
    @include ft;
    font-size: 3.2rem;
    text-align: center;
    margin: 15px 0 15px;
  }
  span {
    display: block;
    font-size: 2.0rem;
  }
}
@include media(pc) {
.ttl {
  padding: 50px 0;
  span {
    padding-top: 10px;
  }
}
}

/* ==================
  common
  ================== */
.l-page {
  .l-inner {
    padding: 0 5%;
  }
}
.cnts-page {
  h2 {
    color: #000;
  }
}


/* ==========================================================================
  PAGE (ABOUT)
  ========================================================================== */
.l-page-about {
  .company-info {
    ul {
      li {
        margin-bottom: 30px;
        dl {
          overflow: hidden;
          
          dt {
            border-bottom: thin solid #000;
            margin-bottom: 15px;
            line-height: .8;
            span {
              display: inline-block;
              
              font-weight: bold;
            }
            .jp {
              font-size: 1.2rem;
            }
            .en {
              margin-left: 10px;
              font-size: 1.2rem;
            }
          }
          dd {
            line-height: 1.4;
            text-align: justify;
            span {
              display: block;
            }
            .jp {
              margin-bottom: 5px;
              font-size: 1.3rem;
            }
            .en {
              color: #000;
              font-size: 1.3rem;
            }
          }
          dd.map {
            margin-top: 15px;
            iframe {
              width: 100%;
              height: 150px;
            }
          }
          dd.interview,
          dd.sns {
            margin-top: 15px;
            h3 {
              @include ft-h;
            }
          }
        }
        a {
          @include arrow;
        }
      }
    }
  }
}
@include media(pc) {
.l-page-about {
  .company-info {
    ul {
      li {
        dl {
          dt {
            span {
            }
            .jp {
              font-size: 1.4rem;
              line-height: 1.6;
            }
            .en {
              font-size: 1.3rem;
            }
          }
          dd {
            span {
              display: block;
              line-height: 1.6;
            }
            .jp {
              margin-bottom: 10px;
              font-size: 1.6rem;
            }
            .en {
              font-size: 1.4rem;
            }
          }
          dd.map {
            margin-top: 15px;
            iframe {
              width: 100%;
              height: 150px;
            }
          }
        }
        a {
          font-size: 1.4rem;
          line-height: 1;
          margin-bottom: 10px;
        }
        a:hover {
          opacity: .7;
        }
      }
    }
  }
}
}

// プライバシーポリシー
.policy {
  text-align: left;
  line-height: 1.4;
  h2 {
    font-weight: bold;
    text-align: center;
    font-size: 2.0rem;
    margin-bottom: 15px;
  }
  .lead {
    margin-bottom: 20px;
  }
  dl {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    dt {
      font-weight: bold;
      margin-bottom: 10px;
    }
    dd {
      p, ul {
        margin-bottom: 15px;
        font-size: 14px;
      }
      ul {
        padding-left: 10px;
      }
    }
    ul {
      li {
        padding-left: 10px;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          content: '';
        	width: 6px;
        	height: 6px;
        	display: inline-block;
        	border-radius: 100%;
        	background: #727171;
        	position: relative;
        	left: -10px;
        	top: -2px;
        }
      }
    }
  }
}


/* ==========================================================================
  PAGE (LUCKAND)
  ========================================================================== */
.l-page {
  .bt,
  .luckand-web,
  .luckand-place {
    margin-bottom: 7.5%;
  }
  .l-mv {
    margin-bottom: 5%;
  }
  h2 {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 15px;
    span {
      display: block;
      font-size: 1.4rem;
    }
  }
  p {
    font-size: 1.2rem;
    &.jp {
      line-height: 1.7;
      margin-bottom: 1rem;
    }
    &.en {
      line-height: 1.5;
    }
  }
  .lead {
    margin-bottom: 15px;
    p {
      text-align: center;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  .photo {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 5%;
  }
  .txt {
    text-align: center;
  }
  .btn-wrap {
    padding-top: 30px;
  }
  a {
    color: colors(default);
  }
}

@include media(pc) {
.l-page {
  .bt,
  .luckand-web,
  .luckand-place {
    margin-bottom: 60px;
  }
  .l-mv {
    margin-bottom: 5%;
  }
  p {
    font-size: 1.4rem;
  }
  .lead {
    margin-bottom: 15px;
    p {
      font-size: 1.6rem;
    }
  }
  .photo {
    margin-bottom: 5%;
  }
}
}








/* ==========================================================================
  PAGE (CONTACT)
  ========================================================================== */
.contact-box-select {
  width: 100%;
  max-width: 720px;
  margin-bottom: 30px;
  margin: 0 auto;
  .select-group {
      position: relative;
      display: inline-block;
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
      padding: 0;
      vertical-align: middle;
      border: 2px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      background-repeat: no-repeat;
  }
  .select-group br {
    display: none;
  }
  .select-group:after {
    position: absolute;
    top: 0;
    right: 2.5%;
    bottom: 0;
    width: 0;
    height: 0;
    margin: auto;
    content: '';
    border-top: 4px solid #999;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  .select {
    appearance: none;
    position: relative;
    z-index: 1;  
    display: block;
    width: 120%;
    padding: 10px;
    border: none;
    border-radius: 0;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    line-height: 1.8;
    text-indent: 8px;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.contact-box-form {
  width: 100%;
  h2 {
    color: #000;
  }
  dl {
    width: 100%;
    max-width: 720px;
    padding: 30px 5% 15px;
    margin: 0 auto;
    background: #f5f5f5;
    border-radius: 4px;
    dt {
      font-weight: bold;
      margin-bottom: 30px;
      h2 {
        font-size: 2.0rem;
        font-weight: bold;
        line-height: 1.2;
        span {
          font-size: 1.6rem;
          padding-top: 10px;
          display: block;
        }
      }
    }
    dd {
      width: 100%;
      p {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      input,
      textarea {
        width: 100%;
        margin-top: 5px;
        padding: 5px 10px;
        font-size: 107%;
        border-radius: 2px;
        border: 2px solid #333;
      }
      input.wpcf7-submit {
        padding: 15px 30px;
        font-size: 1.2em;
        background-color: #000;
        color: #fff;
        border-style: none;
        border-radius: 4px;
        cursor: pointer;
        transition: all .15s ease-out;
        font-weight: bold;
      }
    }
  }
}


@include media(pc) {
.contact-box-select {
  width: 100%;
  max-width: 720px;
  margin-bottom: 2.5%;
  margin: 0 auto;
}
.select {
  
}
.contact-box-form input.wpcf7-submit:hover {
    background-color: colors(orange);
    color: #fff;
}
}

