@charset "UTF-8";

/* ==========================================================================
  Reset
  ========================================================================== */

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block;
}
ul, ol {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote::before, blockquote::after, q::before, q::after {
  content: "";
  content: none;
}
img {
  font-size: 0;
  line-height: 0;
}
a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  font-weight: bold;
  font-style: italic;
  color: #000;
}
del {
  text-decoration: line-through;
}
abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
fieldset, img {
  line-height: 0;
  vertical-align: top;
}
hr {
  display: none;
}
input, select {
  vertical-align: middle;
}
html {
  overflow-y: scroll;
  *zoom: 1;

  /* firefox */
}
* {
  margin: 0;
  padding: 0;
}
img {
  vertical-align: bottom;
}
html, body {
  width: 100%;
  height: 100%;
}
ul, li {
  list-style: none;
}
b {
  font-weight: bold;
}
iframe {
  border: none;
  outline: none;
}
