//pseudo
@mixin pseudo {
  content: "";
  position: absolute;
  display: block;
}

// box shadow
@mixin shadowbox {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    height: 15px;
    background: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0));
  }
}

// arrow
@mixin arrow {
  position: relative;
  display: block;
  margin-top: 5px;
  font-size: 1.2rem;
  padding: 0 0 0 20px;
  &::before, &::after {
    @include pseudo;
    background: colors(orange);
  }
  &::before {
    left: 10px;
    bottom: 5px;
    width: 5px;
    height: 1px;
    transform: rotate(45deg)
  }
  &::after {
    left: 0;
    bottom: 3px;
    width: 14px;
    height: 1px;
  }
}

@mixin arrow-right {
  padding: 0 20px 0 0;
  &::before {
    right: 0;
    left: auto;
  }
  &::after {
    width: 20px;
    right: 0;
    left: auto;
  }
}

// Futura PT Book
@mixin ft {
font-family: futura-pt, sans-serif;
font-weight: 400;
font-style: normal;
}

// Futura PT Book Oblique
@mixin ft-o {
font-family: futura-pt, sans-serif;
font-weight: 400;
font-style: italic;
}

// Futura PT Heavy
@mixin ft-h {
font-family: futura-pt, sans-serif;
font-weight: 700;
font-style: normal;
}

// Futura PT Heavy Oblique
@mixin ft-h-o {
font-family: futura-pt, sans-serif;
font-weight: 700;
font-style: italic;
}

// Futura PT Cond Book
@mixin ft-cnd {
font-family: futura-pt-condensed, sans-serif;
font-weight: 400;
font-style: normal;
}

// Futura PT Cond Book Oblique
@mixin ft-cnd-italic {
font-family: futura-pt-condensed, sans-serif;
font-weight: 400;
font-style: italic;
}

// Futura PT Cond Bold
@mixin ft-cnd-b {
font-family: futura-pt-condensed, sans-serif;
font-weight: 700;
font-style: normal;
}

// Futura PT Cond Bold Oblique
@mixin ft-cnd-b-o {
font-family: futura-pt-condensed, sans-serif;
font-weight: 700;
font-style: italic;
}