@charset "UTF-8";
/* ==========================================================================
  Reset
  ========================================================================== */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline; }

article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block; }

ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after, q::before, q::after {
  content: "";
  content: none; }

img {
  font-size: 0;
  line-height: 0; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  font-weight: bold;
  font-style: italic;
  color: #000; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

fieldset, img {
  line-height: 0;
  vertical-align: top; }

hr {
  display: none; }

input, select {
  vertical-align: middle; }

html {
  overflow-y: scroll;
  *zoom: 1;
  /* firefox */ }

* {
  margin: 0;
  padding: 0; }

img {
  vertical-align: bottom; }

html, body {
  width: 100%;
  height: 100%; }

ul, li {
  list-style: none; }

b {
  font-weight: bold; }

iframe {
  border: none;
  outline: none; }

/* ==========================================================================
  Base
  ========================================================================== */
* {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  color: #000;
  font-size: 1.6em;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: .02em;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
  @media screen and (min-width: 769px) {
    body {
      min-width: 1280px; } }
  @media screen and (max-width: 768px) {
    body {
      font-size: 1.4em; } }

img {
  width: 100%;
  height: auto; }

::selection {
  background: #f19100;
  color: #fff; }

/* ---------------
	Font
------------------ */
.en {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal; }

/* ---------------
	Link
------------------ */
a {
  text-decoration: none;
  transition: all .25s ease-out; }
  a:link {
    color: #000; }
  a:visited {
    color: #000; }
  a:hover {
    color: #f19100; }
  a:active {
    color: #f19100; }

@media screen and (min-width: 769px) {
  a img {
    transition: all .25s ease-out; }
  a:hover img {
    opacity: .5;
    filter: grayscale(100%); } }

/* ---------------
	Button
------------------ */
.btn-wrap {
  display: flex;
  justify-content: center; }
  .btn-wrap a.btn {
    display: block;
    min-width: 85px;
    margin: 0 2.5%;
    padding: 6px 8px;
    border-radius: 2px;
    border: 1px solid #000;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center; }
    .btn-wrap a.btn:hover {
      background: #000;
      color: #fff; }
    .btn-wrap a.btn-blk {
      background: #000;
      color: #fff; }
      .btn-wrap a.btn-blk:hover {
        background: #fff;
        color: #000;
        border: 1px solid #fff; }
    .btn-wrap a.btn-wht {
      background: #fff;
      color: #000; }
      .btn-wrap a.btn-wht:hover {
        background: #f19100;
        color: #fff; }

/* ---------------
	Animation
------------------ */
.animation {
  opacity: 0;
  transition: opacity .5s ease-out; }
  .animation.is-show {
    opacity: 1; }

.dropin {
  opacity: 0;
  transition: opacity .5s ease-out; }
  .dropin.is-show {
    opacity: 1; }

/* ==========================================================================
  Responsive
  ========================================================================== */
.pc {
  display: block; }
  @media screen and (max-width: 768px) {
    .pc {
      display: none; } }

.sp {
  display: none; }
  @media screen and (max-width: 768px) {
    .sp {
      display: block; } }

/* ==========================================================================
  Layout
  ========================================================================== */
.l-main, .l-aside, .l-footer, .l-inner {
  position: relative; }

.l-wrapper {
  padding-top: 58px; }

/* ==========================================================================
  Header
  ========================================================================== */
.l-header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 58px;
  background: rgba(255, 255, 255, 0.9); }

@media screen and (min-width: 769px) {
  .l-header {
    background: rgba(255, 255, 255, 0.9); } }

.header-inner {
  padding: 8px 8px 8px 58px; }

.header-logo {
  position: absolute;
  width: 42px;
  height: auto;
  left: 58px;
  top: 8px; }

.cls-1 {
  fill: #f19100; }

.cls-2 {
  fill: #fff; }

/* ---------------
	SNS
------------------ */
/* SNS(small) */
.l-header .sns {
  position: absolute;
  right: 8px;
  top: 14px; }

.sns {
  overflow: hidden; }

.sns ul {
  display: table;
  margin: 0 auto 0;
  text-align: center; }

.sns ul li {
  display: table-cell;
  padding: 0 4px; }

.sns ul li a {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto; }

.sns ul li a .icon-fill {
  transition: all 0.25s ease-out;
  fill: #000; }

.sns ul li a .icon-frame {
  transition: all 0.5s ease-out;
  fill: #fff;
  transform: scale(0);
  transform-origin: 50% 50% 0; }

@media screen and (min-width: 769px) {
  .sns ul li a:hover .icon-fill {
    fill: #fff; }
  .sns ul li a:hover .icon-frame {
    fill: #000;
    transform: scale(1); } }

/* ==========================================================================
  Navigation
  ========================================================================== */
/*開閉ボタン*/
@media screen and (max-width: 768px) {
  .nav-trigger {
    position: fixed;
    z-index: 101;
    width: 40px;
    height: 40px;
    top: 8px;
    left: 8px;
    z-index: 100;
    background: #000;
    border-radius: 4px;
    padding: 8px;
    transition: 0.5s ease-out; }
    .nav-trigger div {
      position: relative; }
      .nav-trigger div span {
        display: block;
        width: 100%;
        height: 1px;
        background: #fff;
        position: absolute;
        left: 0;
        transition: 0.5s ease-out; }
  .nav-trigger.active {
    background: #fff; }
  .nav-trigger span:nth-of-type(1) {
    top: 3px; }
  .nav-trigger span:nth-of-type(2) {
    top: 11px;
    width: 75%; }
  .nav-trigger span:nth-of-type(3) {
    top: 19px;
    width: 55%; }
  .nav-trigger.active span {
    background: #000; }
    .nav-trigger.active span:nth-of-type(1), .nav-trigger.active span:nth-of-type(3) {
      width: 10px; }
    .nav-trigger.active span:nth-of-type(2) {
      width: 100%; }
    .nav-trigger.active span:nth-of-type(1) {
      transform: translate(-1px, 4px) rotate(-45deg); }
    .nav-trigger.active span:nth-of-type(3) {
      transform: translate(-1px, -4px) rotate(45deg); } }

.nav-wrap h3 {
  color: #fff;
  padding: 10px 10px 0;
  margin-bottom: 15px;
  font-size: 2.4rem; }

.nav-wrap .cat-list {
  position: relative; }
  .nav-wrap .cat-list ul {
    display: flex;
    flex-wrap: wrap; }
    .nav-wrap .cat-list ul li {
      width: 50%;
      margin-bottom: 15px; }
      .nav-wrap .cat-list ul li a {
        display: block;
        position: relative;
        font-size: 1.2rem;
        color: #fff;
        padding: 0 10px 0 20px; }
        .nav-wrap .cat-list ul li a::after {
          content: "";
          position: absolute;
          display: block;
          width: 6px;
          height: 1px;
          top: auto;
          bottom: 2px;
          right: auto;
          left: 10px;
          margin: auto auto 0 0;
          background: #fff; }

.nav-wrap .tag-list {
  position: relative;
  padding-bottom: 26px;
  margin-bottom: 15px; }
  @media screen and (min-width: 769px) {
    .nav-wrap .tag-list {
      padding-bottom: 37px;
      margin-bottom: 30px; } }
  .nav-wrap .tag-list .btn-more {
    position: absolute;
    margin: 0;
    left: 10px;
    bottom: 0; }
    .nav-wrap .tag-list .btn-more a {
      display: block;
      position: relative;
      background: transparent;
      padding: 2px 6px 6px;
      border-radius: 3px;
      border: 1px solid #fff;
      line-height: 1; }
      .nav-wrap .tag-list .btn-more a::before {
        content: "MORE TAGS";
        font-size: 1.0rem;
        line-height: 1;
        color: #fff; }
  .nav-wrap .tag-list ul {
    position: relative;
    padding: 0 10px;
    max-height: 96px;
    overflow: hidden;
    transition: max-height 0.25s; }
    .nav-wrap .tag-list ul li {
      display: inline-block;
      margin-bottom: 10px; }
      .nav-wrap .tag-list ul li a {
        display: block;
        position: relative;
        height: 22px;
        font-size: 1.0rem;
        line-height: 1;
        color: #000;
        padding: 6px 9px 5px;
        border-radius: 11px;
        background: #eee; }
        .nav-wrap .tag-list ul li a:hover {
          background: #bbb; }

.nav-wrap .tag-list.is-active .btn-more a::before {
  content: "CLOSE"; }

.nav-wrap .tag-list.is-active ul {
  max-height: 100vh; }

@media screen and (max-width: 768px) {
  .nav-wrap {
    position: fixed;
    overflow: hidden;
    left: -100%;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: 0.25s ease-out;
    padding: 66px 0 8px;
    overflow-y: auto; }
  .nav-wrap.active {
    left: 0;
    width: 100%;
    height: 100%;
    background: #000; }
  .nav-list ul li {
    font-size: 2.4rem;
    color: #fff; }
    .nav-list ul li.design a {
      cursor: default; }
  .nav-list ul a {
    overflow: hidden;
    position: relative;
    display: block;
    padding: 10px 10px 0;
    margin-bottom: 20px;
    opacity: 0;
    color: #fff;
    line-height: 0.9;
    transition: 0.5s ease-out; }
    .nav-list ul a span.nav-text {
      position: relative;
      z-index: 1;
      display: inline-block;
      font-family: futura-pt, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 2.4rem; }
    .nav-list ul a span.nav-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      margin: 0 auto;
      background: #333;
      transform: scaleX(0);
      transform-origin: top left; }
    .nav-list ul a span.nav-line2 {
      position: absolute;
      bottom: 0;
      right: 5%;
      width: 22px;
      height: 1px;
      margin: 0 auto;
      background: #fff; }
      .nav-list ul a span.nav-line2::after {
        content: "";
        position: absolute;
        display: block;
        width: 7px;
        height: 1px;
        top: auto;
        bottom: 3px;
        right: -1px;
        left: auto;
        margin: auto 0 0 auto;
        background: #fff;
        transform: rotate(45deg); }
  .nav-wrap.active .nav-list ul a {
    opacity: 1; } }

@media screen and (min-width: 769px) {
  .nav-wrap {
    position: fixed;
    overflow-y: auto;
    left: 0;
    top: 0;
    z-index: 999;
    width: 300px;
    height: 100%;
    background: #000;
    transition: 0.25s ease-out; }
    .nav-wrap .logo {
      width: 96px;
      margin: 20px; }
  .nav-list ul {
    padding: 46px 0 8px; }
    .nav-list ul li.design {
      /*
      padding: 10px 10px 0;
  		margin-bottom: 20px;
      line-height: 0.9;
      @include ft;
      font-size: 2.4rem;
      color: #fff;
*/ }
      .nav-list ul li.design a span.nav-line {
        display: none; }
    .nav-list ul a {
      overflow: hidden;
      position: relative;
      display: block;
      padding: 10px 10px 0;
      margin-bottom: 20px;
      opacity: 0;
      color: #fff;
      line-height: 0.9;
      transition: 0.25s ease-out; }
      .nav-list ul a:hover {
        color: #f19100; }
      .nav-list ul a span.nav-text {
        position: relative;
        z-index: 1;
        display: inline-block;
        font-family: futura-pt, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 2.4rem; }
      .nav-list ul a span.nav-line {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        margin: 0 auto;
        background: #333;
        transform: scaleX(1);
        transform-origin: top left; }
      .nav-list ul a span.nav-line2 {
        display: block;
        position: absolute;
        bottom: 0;
        right: 110%;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background: #fff;
        transition: all .25s ease-out; }
        .nav-list ul a span.nav-line2::after {
          content: "";
          position: absolute;
          display: block;
          width: 7px;
          height: 1px;
          top: auto;
          bottom: 3px;
          right: -1px;
          left: auto;
          margin: auto 0 0 auto;
          background: #fff;
          transform: rotate(45deg); }
  .nav-wrap .nav-list ul a {
    opacity: 1;
    position: relative; }
    .nav-wrap .nav-list ul a:hover span.nav-line2 {
      right: 0; }
  .nav-wrap .cat-list ul li {
    margin-bottom: 15px; }
    .nav-wrap .cat-list ul li a {
      display: block;
      position: relative;
      font-size: 1.2rem;
      color: #fff;
      padding: 0 10px 0 20px; }
      .nav-wrap .cat-list ul li a::after {
        content: "";
        position: absolute;
        display: block;
        width: 6px;
        height: 1px;
        top: auto;
        bottom: 2px;
        right: auto;
        left: 10px;
        margin: auto auto 0 0;
        background: #fff; }
  .nav-wrap .tag-list ul {
    padding: 0 10px; }
    .nav-wrap .tag-list ul li {
      display: inline-block;
      margin-bottom: 15px; }
      .nav-wrap .tag-list ul li a {
        display: block;
        position: relative;
        height: 22px;
        font-size: 1.1rem;
        line-height: 1;
        color: #000;
        padding: 5px 10px;
        border-radius: 11px;
        background: #eee; }
        .nav-wrap .tag-list ul li a:hover {
          background: #bbb; } }

/* ----------------
----------------
Pagenation
----------------
---------------- */
.pagenation {
  margin: 5% auto 0;
  width: 100%;
  text-align: center; }

.pagenation ul {
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  padding: 0;
  margin: 0 auto; }

.pagenation ul li {
  display: block;
  overflow: hidden;
  border-radius: 3px;
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  border: 1px solid #1a1a1a;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0 10px; }

.pagenation ul li.next,
.pagenation ul li.prev {
  width: 50px !important; }

.pagenation ul li a {
  color: #1a1a1a;
  display: block; }

.pagenation ul li a:hover {
  background: #1a1a1a;
  color: #fff; }

.pagenation ul li.active {
  color: #fff;
  background: #1a1a1a; }

@media screen and (min-width: 769px) {
  .pagenation {
    margin: 30px auto 60px;
    width: 100%;
    max-width: 720px; }
  .pagenation ul li {
    display: block;
    border-radius: 3px;
    width: 36px !important;
    height: 36px !important;
    font-size: 18px;
    line-height: 34px;
    margin: 0 10px; }
  .pagenation ul li.next,
  .pagenation ul li.prev {
    width: 60px !important; } }

/* ==========================================================================
  Footer
  ========================================================================== */
.l-footer {
  position: relative;
  text-align: center;
  padding: 8px;
  font-size: 1.2rem;
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  font-style: normal;
  background: #f19100;
  color: #fff; }

@media screen and (min-width: 769px) {
  .l-footer {
    position: fixed;
    left: -290px;
    bottom: 0;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.9); } }

.btn-gotop {
  display: none;
  position: fixed;
  left: -15px;
  bottom: 30px;
  margin: 0;
  padding: 4px;
  transform: rotate(-90deg);
  z-index: 100; }
  .btn-gotop a {
    position: relative;
    display: block;
    width: 68px;
    height: 20px; }
    .btn-gotop a span {
      color: #f19100;
      font-size: .9rem; }
    .btn-gotop a::before, .btn-gotop a::after {
      content: "";
      position: absolute;
      display: block;
      height: 1px;
      background: #f19100;
      z-index: 2; }
    .btn-gotop a::before {
      width: 10px;
      right: -1px;
      bottom: 12px;
      transform: rotate(45deg); }
    .btn-gotop a::after {
      width: 70px;
      right: 0;
      bottom: 8px; }

/* ==========================================================================
  Main
  ========================================================================== */
@media screen and (min-width: 769px) {
  .l-main,
  .l-footer {
    padding-left: 300px; } }

/* ==================
  common
  ================== */
.none-txt {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  text-align: center;
  padding: 4.0rem 0; }

/* ==========================================================================
  TOP PAGE
  ========================================================================== */
/* ==================
  common
  ================== */
.cnts h2 {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 15px;
  color: #fff; }

@media screen and (min-width: 769px) {
  .cnts h2 {
    font-size: 3.8rem;
    margin-bottom: 30px; } }

.cnts .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%; }
  .cnts .swiper-slide a article {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .cnts .swiper-slide a article .txt {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: transparent;
      text-align: center;
      display: none;
      border: 0px solid #fff;
      transition: border .25s ease-out; }
      .cnts .swiper-slide a article .txt span {
        display: block;
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.8; }

@media screen and (min-width: 769px) {
  .cnts .swiper-slide a:hover article {
    background: black; }
    .cnts .swiper-slide a:hover article .thumb img {
      transform: scale(1.1); }
    .cnts .swiper-slide a:hover article .txt {
      display: flex;
      align-items: center;
      justify-content: center; }
      .cnts .swiper-slide a:hover article .txt span {
        display: block; } }

/* ==================
  Logo
  ================== */
.cnts-logo {
  margin-top: 15%;
  margin-bottom: 15%;
  text-align: center; }
  .cnts-logo h1 {
    width: 50%;
    max-width: 320px;
    margin: 0 auto 15px; }
  .cnts-logo .catch {
    position: relative;
    overflow: hidden;
    margin-bottom: 15%;
    height: 18px; }
    .cnts-logo .catch span {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      margin: auto auto;
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.8rem; }
  .cnts-logo .anc ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    overflow: hidden;
    height: 18px; }
    .cnts-logo .anc ul li {
      width: 25%;
      position: relative;
      height: 18px;
      border-right: 1px solid #000;
      /*
        &:nth-child(1) {
          width: 60px;
        }
        &:nth-child(2) {
          width: 80px;
        }
        &:nth-child(3) {
          width: 50px;
        }
        &:nth-child(4) {
          width: 60px;
        }
*/ }
      .cnts-logo .anc ul li:last-child {
        border: none; }
      .cnts-logo .anc ul li a {
        position: absolute;
        display: block;
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-family: futura-pt, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.8rem; }

@media screen and (min-width: 769px) {
  .cnts-logo {
    position: relative;
    margin-top: 60px;
    margin-bottom: 30px; }
    .cnts-logo h1 {
      width: 256px;
      height: 248px;
      margin: 0 auto 30px; }
    .cnts-logo .catch {
      height: auto;
      margin-bottom: 90px;
      height: 32px; }
      .cnts-logo .catch span {
        font-size: 2.4rem; }
    .cnts-logo .anc {
      position: relative;
      width: 60%;
      margin: 0 auto; }
      .cnts-logo .anc ul {
        position: relative;
        width: 100%;
        margin-bottom: 90px; }
        .cnts-logo .anc ul li {
          width: 25%; }
          .cnts-logo .anc ul li a {
            text-decoration: none;
            font-size: 2.2rem; } }

/* ==================
  Recent Works
  ================== */
@media screen and (min-width: 769px) {
  /*
.cnts-recent {
  min-height: 270px;
}
*/ }

.ttl-insta {
  position: relative;
  width: 34%;
  text-align: center;
  font-size: 1.3rem;
  background: #1a1a1a;
  color: #fff;
  border-radius: 4px 4px 0 0;
  margin: 0 auto;
  padding: 7px 0 0; }
  .ttl-insta a {
    display: block;
    position: relative;
    color: #fff !important; }
    .ttl-insta a::before {
      content: "";
      position: relative;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url(../images/common/icon_insta_s_wht.svg) no-repeat;
      background-size: 100% 100%;
      margin-right: 5px;
      vertical-align: bottom; }

@media screen and (min-width: 769px) {
  .ttl-insta {
    width: 20%;
    font-size: 1.8rem;
    color: #fff;
    border-radius: 4px 4px 0 0;
    margin: 0 auto;
    padding: 15px 0 7px; }
    .ttl-insta a::before {
      width: 18px;
      height: 18px;
      margin-right: 7px;
      vertical-align: top; } }

/* ==================
  News-list
  ================== */
.cnts-news {
  background: #fff;
  padding: 15% 0 15%; }
  .cnts-news h2 {
    color: #000; }
  .cnts-news .news-list ul {
    margin-bottom: 30px; }
    .cnts-news .news-list ul::after {
      content: none !important; }
  .cnts-news article {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
    line-height: 1.4;
    padding: 0 2.5% 10px;
    border-bottom: 1px solid #f0f0f0; }
    .cnts-news article .date {
      width: 20%;
      font-family: futura-pt, sans-serif;
      font-weight: 400;
      font-style: normal;
      text-align: left;
      font-size: 1.3rem; }
    .cnts-news article .txt {
      font-size: 1.2rem;
      width: 75%;
      text-align: left; }
  .cnts-news .btn {
    background: #fff; }

@media screen and (min-width: 769px) {
  .cnts-news {
    padding: 90px 0; }
    .cnts-news .news-list ul {
      width: 60%;
      margin: 0 auto 30px; }
    .cnts-news article {
      margin-bottom: 15px;
      padding: 0 0 15px; }
      .cnts-news article .date {
        width: 12%;
        font-family: futura-pt, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        font-size: 1.6rem; }
      .cnts-news article .txt {
        font-size: 1.6rem;
        width: 85%; } }

/* ==================
  Music Works / Media Works /MD
  ================== */
.cnts-works {
  background: #1a1a1a; }
  .cnts-works .l-inner {
    position: relative;
    padding: 0; }
  .cnts-works .sec-wrap {
    position: relative; }
  .cnts-works header {
    position: absolute;
    display: inline-block;
    height: 50px;
    width: 40%;
    left: 0;
    right: 0;
    top: 230px;
    padding: 0;
    margin: 0 auto auto;
    background: #1a1a1a;
    z-index: 3;
    border-radius: 4px 4px 0 0; }
    .cnts-works header h2 {
      position: relative;
      line-height: 50px; }
  .cnts-works .cnts-txt {
    padding: 15% 0 15%;
    background: #1a1a1a;
    color: #fff; }
  .cnts-works h3 {
    text-align: center;
    margin: 0 auto 10px; }
    .cnts-works h3 img {
      display: block;
      width: 96px;
      margin: 0 auto 10px; }
    .cnts-works h3 span {
      font-size: 1.6rem;
      font-family: futura-pt-condensed, sans-serif;
      font-weight: 700;
      font-style: normal; }
  .cnts-works p.lead {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-family: futura-pt-condensed, sans-serif;
    font-weight: 700;
    font-style: normal; }
  .cnts-works p {
    text-align: center;
    line-height: 1.4; }
    .cnts-works p.jp {
      font-size: 1.2rem;
      margin-bottom: 10px; }
    .cnts-works p.en {
      font-family: futura-pt, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.1rem; }
  .cnts-works .btn-wrap {
    padding-top: 30px; }

.carousel {
  margin-bottom: 0;
  width: 100%;
  background: #1a1a1a;
  padding: 1px 0; }
  .carousel .thumb {
    position: relative;
    width: 100%;
    height: auto; }
    .carousel .thumb img {
      width: 100%;
      height: 250px;
      object-fit: cover; }

@media screen and (min-width: 769px) {
  .cnts-works .l-inner {
    padding: 0; }
  .cnts-works .sec-wrap {
    position: relative; }
  .cnts-works header {
    position: absolute;
    display: inline-block;
    height: 80px;
    width: 20%;
    left: 0;
    right: 0;
    top: 360px;
    padding: 0;
    margin: 0 auto auto;
    background: #1a1a1a;
    z-index: 3;
    border-radius: 4px 4px 0 0; }
    .cnts-works header h2 {
      position: relative;
      line-height: 80px; }
  .cnts-works .cnts-txt {
    padding: 90px 0;
    background: #1a1a1a;
    color: #fff; }
  .cnts-works h3 {
    margin: 0 auto 25px; }
    .cnts-works h3 img {
      width: 150px;
      margin: 0 auto 15px; }
    .cnts-works h3 span {
      font-size: 1.8rem; }
  .cnts-works p.lead {
    margin-bottom: 30px;
    font-size: 2.4rem; }
  .cnts-works p {
    line-height: 1.6; }
    .cnts-works p.jp {
      font-size: 1.6rem;
      margin-bottom: 15px; }
    .cnts-works p.en {
      font-family: futura-pt, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.3rem; }
  .cnts-works .btn-wrap {
    padding-top: 30px; }
  .carousel {
    margin-bottom: 0;
    width: 100%; }
    .carousel .thumb {
      position: relative;
      width: 100%;
      height: auto; }
      .carousel .thumb img {
        width: 100%;
        height: 400px;
        object-fit: cover; } }

/* ==================
  Music Works / Media Works
  ================== */
.cnts-design header {
  background: #1a1a1a; }
  .cnts-design header h2 {
    /*
      span.blk {
        color: #fff;
      }
      span.wht {
        color: #fff;
      }  
*/ }

.cnts-design .design-sec-wrap {
  position: relative;
  display: flex;
  /*
    .design-sec-music {
      .btn-wrap {
        a {
          background: #fff;
          color: #000;
        }
      }
    }
    .design-sec-media {
      background: #000;
      background: #fff;
      color: #000;
      .btn-wrap {
        a {
          background: #fff;
          color: #000;
        }
      }
    }
*/ }
  .cnts-design .design-sec-wrap::after {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    height: 72%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #333;
    z-index: 1; }
  .cnts-design .design-sec-wrap .cnts-txt {
    padding: 30% 0 30%; }
  .cnts-design .design-sec-wrap .design-sec-music,
  .cnts-design .design-sec-wrap .design-sec-media {
    width: 50%; }

@media screen and (min-width: 769px) {
  .cnts-design .design-sec-wrap::after {
    height: 72%; }
  .cnts-design .design-sec-wrap .cnts-txt {
    padding: 90px 0; } }

/* ==================
  MD
  ================== */
.cnts-merchandise {
  position: relative; }
  .cnts-merchandise header {
    width: 30%; }
  .cnts-merchandise .sec-wrap .cnt-txt {
    padding: 15% 0 15%; }

@media screen and (min-width: 769px) {
  .cnts-merchandise header {
    width: 20%; }
  .cnts-merchandise .sec-wrap .cnt-txt {
    padding: 90px 0; } }

/* ==================
  Others
  ================== */
.cnts-others {
  background: #f19100;
  color: #fff;
  overflow: hidden; }
  .cnts-others .l-inner {
    padding: 15% 0; }
  .cnts-others h2 {
    text-align: center;
    margin: 0 auto 10px; }
    .cnts-others h2 img {
      display: block;
      width: 96px;
      margin: 0 auto 10px; }
    .cnts-others h2 span {
      font-size: 1.6rem;
      font-family: futura-pt-condensed, sans-serif;
      font-weight: 700;
      font-style: normal; }
  .cnts-others h2 {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.2rem;
    margin-bottom: 30px; }

@media screen and (min-width: 769px) {
  .cnts-others .l-inner {
    padding: 60px 0 90px; }
  .cnts-others h2 {
    text-align: center;
    margin: 0 auto 30px; }
    .cnts-others h2 img {
      display: block;
      width: 128px;
      margin: 0 auto 15px; }
    .cnts-others h2 span {
      font-size: 1.6rem; }
  .cnts-others h2 {
    font-size: 3.8rem;
    margin-bottom: 30px; } }

.services-container .swiper-slide {
  position: relative;
  background: #fff;
  color: #000;
  padding: 30px 10px;
  border-radius: 4px;
  min-height: 270px; }

.services-container h3 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 2.8rem;
  font-family: futura-pt-condensed, sans-serif;
  font-weight: 700;
  font-style: normal; }
  .services-container h3 small {
    display: block;
    font-size: 50%;
    font-weight: bold; }
  .services-container h3 img {
    display: block;
    width: 80%;
    margin: 0 auto; }

.services-container p {
  text-align: center;
  line-height: 1.4; }
  .services-container p.jp {
    font-size: 1.2rem;
    margin-bottom: 5px; }
  .services-container p.en {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem; }

.services-container .btn-wrap {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  top: auto;
  margin: auto auto 0; }
  .services-container .btn-wrap a {
    width: auto;
    display: inline-block;
    margin: 0 auto; }

@media screen and (min-width: 769px) {
  .services-container .swiper-slide {
    min-height: 330px; }
  .services-container h3 {
    margin-bottom: 15px;
    font-size: 2.8rem; }
    .services-container h3 img {
      width: 60%; }
  .services-container p {
    text-align: center;
    line-height: 1.4; }
    .services-container p.jp {
      font-size: 1.6rem;
      margin-bottom: 5px; }
    .services-container p.en {
      font-size: 1.3rem; }
  .services-container .btn-wrap {
    padding-top: 30px; }
    .services-container .btn-wrap a:hover {
      color: #fff;
      background: #f19100;
      border: 1px solid #f19100; } }

/* ==========================================================================
  ARCHIVE PAGE 
  ========================================================================== */
.cnts-explain {
  background: #1a1a1a; }
  .cnts-explain .l-inner {
    padding: 15% 2.5%; }
  .cnts-explain .cnt-txt {
    background: #1a1a1a;
    color: #fff;
    text-align: center; }
    .cnts-explain .cnt-txt h2 {
      width: 50%;
      max-width: 320px;
      margin: 0 auto 30px; }
    .cnts-explain .cnt-txt .lead {
      font-family: futura-pt-condensed, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 2.0rem;
      margin-bottom: 20px; }
    .cnts-explain .cnt-txt p {
      font-size: 1.2rem;
      line-height: 1.4;
      margin-bottom: 10px; }
      .cnts-explain .cnt-txt p:last-child {
        margin-bottom: 0; }

@media screen and (min-width: 769px) {
  .cnts-explain .l-inner {
    padding: 90px 2.5%; }
  .cnts-explain .cnt-txt h2 {
    width: 50%;
    max-width: 220px;
    margin: 0 auto 30px; }
  .cnts-explain .cnt-txt .lead {
    font-family: futura-pt-condensed, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.4rem;
    margin-bottom: 30px; }
  .cnts-explain .cnt-txt p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 15px; }
    .cnts-explain .cnt-txt p:last-child {
      margin-bottom: 0; } }

.cnts-archive {
  position: relative;
  padding: 15% 0; }
  .cnts-archive h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.2rem; }

@media screen and (min-width: 769px) {
  .cnts-archive {
    padding: 60px 0; }
    .cnts-archive h3 {
      margin-bottom: 30px;
      font-size: 3.2rem; } }

.tax-ttl {
  background: #1a1a1a;
  color: #fff;
  padding: 15px 0 5px !important; }
  .tax-ttl .type {
    text-align: center;
    font-size: 1.5rem; }
  .tax-ttl h1 {
    font-size: 2.2rem  !important; }

@media screen and (min-width: 769px) {
  .tax-ttl {
    padding: 30px 0 10px !important; }
    .tax-ttl .type {
      text-align: center;
      font-size: 1.8rem; }
    .tax-ttl h1 {
      font-size: 2.8rem  !important; } }

/* ==========================================================================
  
  NEWS (category-news.php / content.php)
  
  ========================================================================== */
/* ==================
  News List
  ================== */
.news-wrap .news-cat {
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between; }
  .news-wrap .news-cat li {
    margin: 0;
    display: block;
    width: 25%;
    text-align: center;
    font-family: futura-pt, sans-serif;
    font-weight: 700;
    font-style: normal;
    border-bottom: 2px solid #ccc; }
    .news-wrap .news-cat li.is-active {
      border-bottom: 2px solid #000; }
    .news-wrap .news-cat li span {
      display: block;
      padding: 5px 0 15px;
      font-family: futura-pt, sans-serif;
      font-size: 1.4rem;
      font-weight: 700;
      font-style: normal; }

.news-wrap .news-panel {
  display: block;
  margin-bottom: 30px;
  background: #fff;
  padding: 0; }
  .news-wrap .news-panel li {
    width: 100%;
    margin: 0;
    padding: 0; }
    .news-wrap .news-panel li .news-list {
      width: 100%;
      margin: 0;
      padding: 15px 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: transparent; }
      .news-wrap .news-panel li .news-list:before {
        content: "";
        display: block;
        width: 22.5%;
        height: 0;
        order: 1; }
      .news-wrap .news-panel li .news-list:after {
        content: "";
        display: block;
        width: 22.5%;
        height: 0; }
      .news-wrap .news-panel li .news-list li {
        width: 47%;
        margin-bottom: 30px;
        padding: 0 0 15px;
        border-bottom: 1px solid #000;
        /*
          &:nth-child(odd) {
            background: #efefef;
          }
    */ }
        .news-wrap .news-panel li .news-list li a {
          position: relative;
          display: block;
          margin-top: 5px;
          font-size: 1.2rem;
          padding: 0 0 0 20px;
          padding: 0 20px 0 0;
          height: 100%;
          padding: 0 0 10px;
          margin-top: 0; }
          .news-wrap .news-panel li .news-list li a::before, .news-wrap .news-panel li .news-list li a::after {
            content: "";
            position: absolute;
            display: block;
            background: #f19100; }
          .news-wrap .news-panel li .news-list li a::before {
            left: 10px;
            bottom: 5px;
            width: 5px;
            height: 1px;
            transform: rotate(45deg); }
          .news-wrap .news-panel li .news-list li a::after {
            left: 0;
            bottom: 3px;
            width: 14px;
            height: 1px; }
          .news-wrap .news-panel li .news-list li a::before {
            right: 0;
            left: auto; }
          .news-wrap .news-panel li .news-list li a::after {
            width: 20px;
            right: 0;
            left: auto; }
        .news-wrap .news-panel li .news-list li article .thumb {
          margin-bottom: 10px; }
        .news-wrap .news-panel li .news-list li article .date {
          margin-bottom: 5px;
          font-family: futura-pt, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 1.4rem; }
        .news-wrap .news-panel li .news-list li article h2 {
          margin-bottom: 10px;
          line-height: 1.4;
          font-size: 1.4rem;
          text-align: left; }
        .news-wrap .news-panel li .news-list li article .cat {
          font-family: futura-pt, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 1rem; }
    .news-wrap .news-panel li .notfound {
      padding: 15% 0;
      text-align: center; }
      .news-wrap .news-panel li .notfound span {
        display: block;
        margin-bottom: 1em; }
        .news-wrap .news-panel li .notfound span .en {
          font-family: futura-pt, sans-serif;
          font-weight: 700;
          font-style: normal; }

.news-detail {
  margin-bottom: 30px; }
  .news-detail .img-main {
    overflow: hidden;
    position: relative;
    min-height: 200px;
    margin-bottom: 20px; }
    .news-detail .img-main img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0; }
  .news-detail .news-ttl {
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000; }
    .news-detail .news-ttl .date {
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      font-style: normal;
      margin-bottom: 10px;
      font-size: 1.4rem; }
    .news-detail .news-ttl h2 {
      margin-bottom: 15px;
      text-align: left;
      line-height: 1.4; }
  .news-detail .news-cnt {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000; }
    .news-detail .news-cnt p {
      margin-bottom: 15px;
      line-height: 1.75; }

@media screen and (min-width: 769px) {
  .news-wrap .news-cat li span {
    font-size: 1.6rem; }
  .news-wrap .news-panel li .news-list {
    padding: 30px 0 0; }
    .news-wrap .news-panel li .news-list li {
      width: 22.5%; }
  .news-wrap .news-panel li .notfound {
    font-size: 1.6rem; }
  .news-detail {
    margin-bottom: 30px; }
    .news-detail .img-main {
      overflow: hidden;
      position: relative;
      min-height: 200px;
      margin-bottom: 20px; }
      .news-detail .img-main img {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0; }
    .news-detail .news-ttl .date {
      font-size: 1.6rem; }
    .news-detail .news-ttl h2 {
      font-size: 2.6rem; }
    .news-detail .news-cnt p {
      font-size: 1.4rem; } }

/* ==========================================================================
  WORKS PAGE (MUSIC / MEDIA / MERCHANDISE )
  ========================================================================== */
/* ==================
  Recent List
  ================== */
.recent-list ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2.5% 2.5% 0;
  background: #1a1a1a; }
  .recent-list ul::after {
    content: '';
    display: block;
    width: 31.5%;
    height: 0; }
  .recent-list ul li {
    width: 31.5%;
    margin-bottom: 2.5%; }
    .recent-list ul li a, .recent-list ul li img {
      display: block; }
    .recent-list ul li a {
      position: relative;
      overflow: hidden; }
      .recent-list ul li a img {
        width: 100%; }

@media screen and (min-width: 769px) {
  .recent-list ul {
    padding: 15px 15px 0; }
    .recent-list ul::after {
      width: 15.5%; }
    .recent-list ul li {
      width: 15.5%;
      margin-bottom: 15px; }
      .recent-list ul li a, .recent-list ul li img {
        display: block; }
      .recent-list ul li a {
        position: relative;
        overflow: hidden; }
        .recent-list ul li a img {
          width: 100%; } }

/* ==================
  Archive List
  ================== */
.archive-list ul,
.thumbs-list ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2.5% 0;
  padding-top: 2.5%;
  background: #fff; }
  .archive-list ul::after,
  .thumbs-list ul::after {
    content: '';
    display: block;
    width: 31.5%; }
  .archive-list ul li,
  .thumbs-list ul li {
    width: 31.5%;
    margin-bottom: 2.5%; }
    .archive-list ul li a, .archive-list ul li img,
    .thumbs-list ul li a,
    .thumbs-list ul li img {
      display: block; }
    .archive-list ul li a,
    .thumbs-list ul li a {
      position: relative;
      overflow: hidden; }
      .archive-list ul li a img,
      .thumbs-list ul li a img {
        width: 100%; }

@media screen and (min-width: 769px) {
  .archive-list ul,
  .thumbs-list ul {
    padding: 0 5% 0;
    background: #fff; }
    .archive-list ul::before, .archive-list ul::after,
    .thumbs-list ul::before,
    .thumbs-list ul::after {
      content: "";
      display: block;
      width: 23%;
      height: 0; }
    .archive-list ul::before,
    .thumbs-list ul::before {
      order: 1; }
    .archive-list ul li,
    .thumbs-list ul li {
      width: 23%;
      margin-bottom: 30px; }
      .archive-list ul li a, .archive-list ul li img,
      .thumbs-list ul li a,
      .thumbs-list ul li img {
        display: block; }
      .archive-list ul li a,
      .thumbs-list ul li a {
        position: relative;
        overflow: hidden; }
        .archive-list ul li a img,
        .thumbs-list ul li a img {
          width: 100%; } }

/* ==========================================================================
  WORKS PAGE (DETAIL)
  ========================================================================== */
.cnts-detail .btn-wrap {
  padding: 30px 0; }

.detail-img .thumb {
  max-width: 640px;
  margin: 0 auto 15px; }

.detail-txt {
  margin: 0 auto 30px;
  padding: 0 5%;
  letter-spacing: .05em; }
  .detail-txt h1 {
    font-size: 2.0rem;
    margin-bottom: 15px;
    line-height: 1.4; }
  .detail-txt p {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 1.5em; }
    .detail-txt p:last-child {
      margin-bottom: 0; }

@media screen and (min-width: 769px) {
  .cnts-detail {
    padding-top: 30px; }
    .cnts-detail .btn-wrap {
      padding: 30px 0; }
  .detail-img .thumb {
    max-width: 640px;
    margin: 0 auto 15px; }
  .detail-txt {
    max-width: 640px;
    margin: 0 auto 30px;
    padding: 30px 0; }
    .detail-txt h1 {
      font-size: 2.4rem;
      line-height: 1.4; }
    .detail-txt p {
      font-size: 1.4rem;
      line-height: 1.6; } }

.cnts-detail .cats, .cnts-detail .tags {
  padding-top: 15px; }
  .cnts-detail .cats h6, .cnts-detail .tags h6 {
    margin-bottom: 10px; }
  .cnts-detail .cats ul, .cnts-detail .tags ul {
    font-size: 0; }
    .cnts-detail .cats ul li, .cnts-detail .tags ul li {
      display: inline-block;
      letter-spacing: -.04em;
      margin-bottom: 10px;
      margin-right: 10px; }
      .cnts-detail .cats ul li a, .cnts-detail .tags ul li a {
        display: block;
        padding: 4px 6px;
        border-radius: 4px;
        background: #f5f5f5;
        font-size: 1.0rem; }

.cnts-detail .date {
  padding-top: 15px;
  margin-bottom: 30px; }

.cnts-detail .share {
  text-align: center; }
  .cnts-detail .share h6 {
    font-weight: bold;
    font-size: 1.4rem;
    padding-right: 10px; }

.cnts-detail .sns {
  text-align: center;
  padding: 15px 0 0; }
  .cnts-detail .sns li {
    vertical-align: middle; }

@media screen and (min-width: 769px) {
  .cnts-detail .cats, .cnts-detail .tags {
    padding-top: 30px; }
    .cnts-detail .cats h6, .cnts-detail .tags h6 {
      margin-bottom: 10px; }
    .cnts-detail .cats ul, .cnts-detail .tags ul {
      font-size: 0; }
      .cnts-detail .cats ul li, .cnts-detail .tags ul li {
        display: inline-block;
        letter-spacing: -.04em;
        margin-bottom: 10px;
        margin-right: 10px; }
        .cnts-detail .cats ul li a, .cnts-detail .tags ul li a {
          font-size: 1.2rem;
          background: #f5f5f5; }
          .cnts-detail .cats ul li a:hover, .cnts-detail .tags ul li a:hover {
            color: #fff;
            background: #f19100; }
  .cnts-detail .date {
    padding-top: 30px; }
  .cnts-detail .share {
    text-align: center; }
    .cnts-detail .share h6 {
      font-weight: bold;
      font-size: 1.4rem;
      padding-right: 10px; }
  .cnts-detail .sns {
    text-align: center;
    padding: 15px 0 0; }
    .cnts-detail .sns li {
      vertical-align: middle; } }

/* ==========================================================================
  Not Found
  ========================================================================== */
/* ==================
  Not Found
  ================== */
.cnts-none .l-inner {
  padding: 0 5% 60px; }
  .cnts-none .l-inner p {
    text-align: center;
    margin-bottom: 1em; }

/* ==========================================================================
  PAGE
  ========================================================================== */
.ttl {
  padding: 2.5% 0; }
  .ttl h1 {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.2rem;
    text-align: center;
    margin: 15px 0 15px; }
  .ttl span {
    display: block;
    font-size: 2.0rem; }

@media screen and (min-width: 769px) {
  .ttl {
    padding: 50px 0; }
    .ttl span {
      padding-top: 10px; } }

/* ==================
  common
  ================== */
.l-page .l-inner {
  padding: 0 5%; }

.cnts-page h2 {
  color: #000; }

/* ==========================================================================
  PAGE (ABOUT)
  ========================================================================== */
.l-page-about .company-info ul li {
  margin-bottom: 30px; }
  .l-page-about .company-info ul li dl {
    overflow: hidden; }
    .l-page-about .company-info ul li dl dt {
      border-bottom: thin solid #000;
      margin-bottom: 15px;
      line-height: .8; }
      .l-page-about .company-info ul li dl dt span {
        display: inline-block;
        font-weight: bold; }
      .l-page-about .company-info ul li dl dt .jp {
        font-size: 1.2rem; }
      .l-page-about .company-info ul li dl dt .en {
        margin-left: 10px;
        font-size: 1.2rem; }
    .l-page-about .company-info ul li dl dd {
      line-height: 1.4;
      text-align: justify; }
      .l-page-about .company-info ul li dl dd span {
        display: block; }
      .l-page-about .company-info ul li dl dd .jp {
        margin-bottom: 5px;
        font-size: 1.3rem; }
      .l-page-about .company-info ul li dl dd .en {
        color: #000;
        font-size: 1.3rem; }
    .l-page-about .company-info ul li dl dd.map {
      margin-top: 15px; }
      .l-page-about .company-info ul li dl dd.map iframe {
        width: 100%;
        height: 150px; }
    .l-page-about .company-info ul li dl dd.interview,
    .l-page-about .company-info ul li dl dd.sns {
      margin-top: 15px; }
      .l-page-about .company-info ul li dl dd.interview h3,
      .l-page-about .company-info ul li dl dd.sns h3 {
        font-family: futura-pt, sans-serif;
        font-weight: 700;
        font-style: normal; }
  .l-page-about .company-info ul li a {
    position: relative;
    display: block;
    margin-top: 5px;
    font-size: 1.2rem;
    padding: 0 0 0 20px; }
    .l-page-about .company-info ul li a::before, .l-page-about .company-info ul li a::after {
      content: "";
      position: absolute;
      display: block;
      background: #f19100; }
    .l-page-about .company-info ul li a::before {
      left: 10px;
      bottom: 5px;
      width: 5px;
      height: 1px;
      transform: rotate(45deg); }
    .l-page-about .company-info ul li a::after {
      left: 0;
      bottom: 3px;
      width: 14px;
      height: 1px; }

@media screen and (min-width: 769px) {
  .l-page-about .company-info ul li dl dt .jp {
    font-size: 1.4rem;
    line-height: 1.6; }
  .l-page-about .company-info ul li dl dt .en {
    font-size: 1.3rem; }
  .l-page-about .company-info ul li dl dd span {
    display: block;
    line-height: 1.6; }
  .l-page-about .company-info ul li dl dd .jp {
    margin-bottom: 10px;
    font-size: 1.6rem; }
  .l-page-about .company-info ul li dl dd .en {
    font-size: 1.4rem; }
  .l-page-about .company-info ul li dl dd.map {
    margin-top: 15px; }
    .l-page-about .company-info ul li dl dd.map iframe {
      width: 100%;
      height: 150px; }
  .l-page-about .company-info ul li a {
    font-size: 1.4rem;
    line-height: 1;
    margin-bottom: 10px; }
  .l-page-about .company-info ul li a:hover {
    opacity: .7; } }

.policy {
  text-align: left;
  line-height: 1.4; }
  .policy h2 {
    font-weight: bold;
    text-align: center;
    font-size: 2.0rem;
    margin-bottom: 15px; }
  .policy .lead {
    margin-bottom: 20px; }
  .policy dl {
    margin-bottom: 30px; }
    .policy dl:last-child {
      margin-bottom: 0; }
    .policy dl dt {
      font-weight: bold;
      margin-bottom: 10px; }
    .policy dl dd p, .policy dl dd ul {
      margin-bottom: 15px;
      font-size: 14px; }
    .policy dl dd ul {
      padding-left: 10px; }
    .policy dl ul li {
      padding-left: 10px;
      margin-bottom: 5px; }
      .policy dl ul li:last-child {
        margin-bottom: 0; }
      .policy dl ul li::before {
        content: '';
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 100%;
        background: #727171;
        position: relative;
        left: -10px;
        top: -2px; }

/* ==========================================================================
  PAGE (LUCKAND)
  ========================================================================== */
.l-page .bt,
.l-page .luckand-web,
.l-page .luckand-place {
  margin-bottom: 7.5%; }

.l-page .l-mv {
  margin-bottom: 5%; }

.l-page h2 {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 15px; }
  .l-page h2 span {
    display: block;
    font-size: 1.4rem; }

.l-page p {
  font-size: 1.2rem; }
  .l-page p.jp {
    line-height: 1.7;
    margin-bottom: 1rem; }
  .l-page p.en {
    line-height: 1.5; }

.l-page .lead {
  margin-bottom: 15px; }
  .l-page .lead p {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold; }

.l-page .photo {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 5%; }

.l-page .txt {
  text-align: center; }

.l-page .btn-wrap {
  padding-top: 30px; }

.l-page a {
  color: #000; }

@media screen and (min-width: 769px) {
  .l-page .bt,
  .l-page .luckand-web,
  .l-page .luckand-place {
    margin-bottom: 60px; }
  .l-page .l-mv {
    margin-bottom: 5%; }
  .l-page p {
    font-size: 1.4rem; }
  .l-page .lead {
    margin-bottom: 15px; }
    .l-page .lead p {
      font-size: 1.6rem; }
  .l-page .photo {
    margin-bottom: 5%; } }

/* ==========================================================================
  PAGE (CONTACT)
  ========================================================================== */
.contact-box-select {
  width: 100%;
  max-width: 720px;
  margin-bottom: 30px;
  margin: 0 auto; }
  .contact-box-select .select-group {
    position: relative;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    vertical-align: middle;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    background-repeat: no-repeat; }
  .contact-box-select .select-group br {
    display: none; }
  .contact-box-select .select-group:after {
    position: absolute;
    top: 0;
    right: 2.5%;
    bottom: 0;
    width: 0;
    height: 0;
    margin: auto;
    content: '';
    border-top: 4px solid #999;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent; }
  .contact-box-select .select {
    appearance: none;
    position: relative;
    z-index: 1;
    display: block;
    width: 120%;
    padding: 10px;
    border: none;
    border-radius: 0;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    line-height: 1.8;
    text-indent: 8px;
    font-size: 1.4rem;
    font-weight: bold; }

.contact-box-form {
  width: 100%; }
  .contact-box-form h2 {
    color: #000; }
  .contact-box-form dl {
    width: 100%;
    max-width: 720px;
    padding: 30px 5% 15px;
    margin: 0 auto;
    background: #f5f5f5;
    border-radius: 4px; }
    .contact-box-form dl dt {
      font-weight: bold;
      margin-bottom: 30px; }
      .contact-box-form dl dt h2 {
        font-size: 2.0rem;
        font-weight: bold;
        line-height: 1.2; }
        .contact-box-form dl dt h2 span {
          font-size: 1.6rem;
          padding-top: 10px;
          display: block; }
    .contact-box-form dl dd {
      width: 100%; }
      .contact-box-form dl dd p {
        margin-bottom: 15px; }
        .contact-box-form dl dd p:last-child {
          margin-bottom: 0; }
      .contact-box-form dl dd input,
      .contact-box-form dl dd textarea {
        width: 100%;
        margin-top: 5px;
        padding: 5px 10px;
        font-size: 107%;
        border-radius: 2px;
        border: 2px solid #333; }
      .contact-box-form dl dd input.wpcf7-submit {
        padding: 15px 30px;
        font-size: 1.2em;
        background-color: #000;
        color: #fff;
        border-style: none;
        border-radius: 4px;
        cursor: pointer;
        transition: all .15s ease-out;
        font-weight: bold; }

@media screen and (min-width: 769px) {
  .contact-box-select {
    width: 100%;
    max-width: 720px;
    margin-bottom: 2.5%;
    margin: 0 auto; }
  .contact-box-form input.wpcf7-submit:hover {
    background-color: #f19100;
    color: #fff; } }
