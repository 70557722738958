@charset "UTF-8";

// 画像パス
$img_path: '../images/';

// メディアクエリ
@mixin media($media_size) {
  // PCの場合
  @if $media_size == pc {
    @media screen and (min-width: 769px) {
      @content;
    }
  }
  // TABの場合
  @else if $media_size == tab {
    @media screen and (max-width: 959px) {
      @content;
    }
  }
  // SPの場合
  @else if $media_size == sp {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
}

// 色
$colors: (
  default: #000,
  green: #93c230,
  orange: #f19100,
);
@function colors($key) {
  @return map-get($colors, $key);
}